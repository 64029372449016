import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Button from 'src/components/Button';
import { Control } from 'react-hook-form';

import TextField from 'src/components/TextField';

import PasswordField from 'src/components/PasswordField';
import Login from 'src/components/Login';
import locale from 'src/locale';

import css from './css';
import { Schema } from './form/schema';

type Props = {
  onSubmit: () => void;
  control: Control<Schema, any>;
};

export default (props: Props) => {
  const { onSubmit, control } = props;

  return (
    <Login
      subtitle={locale.adminLogin.title}
      description={locale.adminLogin.description}
      Icon={<AdminPanelSettingsIcon />}
    >
      <form onSubmit={onSubmit} className={css.form}>
        <TextField
          name="username"
          control={control}
          label={locale.form.username}
        />

        <PasswordField
          name="password"
          control={control}
          label={locale.form.password}
        />

        <input type="hidden" value="1" name="rememberMe" />

        <Button type="submit" className={css.submit}>
          {locale.general.login}
        </Button>
      </form>

      <p className={css.version}>v0.5.2</p>
    </Login>
  );
};
